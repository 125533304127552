<template>
  <!-- 商标在线自主注册页-广告栏标题-->
  <div class="title">
      <p class="boxcopy">{{this.word01}}</p>
      <div class="box">
      <div class="left">
        <div class="gang"></div>
        <div class="quan"></div>
      </div>
      <strong class="word01">{{this.word02}}<span class="word02">{{this.word03}}</span></strong>
      <div class="left">
        <div class="quan"></div>
        <div class="gang"></div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  props:["word01","word02","word03"],
  data(){
    return{

    }
  }
}
</script>

<style scoped lang="scss">
.title {
  width: 100%;
  height: 3.75rem /* 60px -> 3.75rem */;
  position: relative;
  .boxcopy {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translatex(-50%);
    font-size: 2.375rem /* 38px -> 2.375rem */;
    font-weight: bold;
    color: rgba($color: #4D89F1, $alpha: .15);
  }
  .box {
    position: absolute;
    top: 27px;
    left: 50%;
    height: 1.9375rem /* 31px -> 1.9375rem */;
    transform: translatex(-50%);
    display: flex;
    align-items: center;
    .left{
      display: flex;
      align-items: center;
      .gang {
        height: 0.0625rem /* 1px -> 0.0625rem */;
        width: 2.0625rem /* 33px -> 2.0625rem */;
        background-color: #4D89F1;
      }
      .quan {
        width: 0.625rem /* 10px -> 0.625rem */;
        height: 0.625rem /* 10px -> 0.625rem */;
        border: 0.0625rem /* 1px -> 0.0625rem */solid #4D89F1;
        border-radius: 50%;
      }
    }
    .word01 {
      font-size: 1.875rem /* 30px -> 1.875rem */;
      color: #333;
      margin-left: 2.3125rem /* 37px -> 2.3125rem */;
    }
    .word02 {
      font-size: 1.875rem /* 30px -> 1.875rem */;
      color: #4D89F1;
      margin-right: 2.3125rem /* 37px -> 2.3125rem */;
    }
  }
}

</style>