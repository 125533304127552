<template>
  <!-- 相关材料 -->
  <div class="AR01">
    <div class="header">
      <div class="lan"></div>
      <p>相关材料</p>
    </div>
    <div class="materials" v-show="show">
      <div class="materialsBox">
        <p class="title">企业申请注册</p>
        <div class="field">
          <p class="num">1.</p>
          <p>营业执照复印件(盖章)</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
        <div class="field">
          <p class="num">2.</p>
          <p>商标代理委托书(盖章)</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
        <div class="field">
          <p class="num">3.</p>
          <p>清晰的商标图样</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
        <div class="field">
          <p class="num">4.</p>
          <p>注册类别及小项</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
      </div>

      <div class="materialsBox">
        <p class="title">个人申请注册</p>
        <div class="field">
          <p class="num">1.</p>
          <p>个体户营业执照（原件）</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
        <div class="field">
          <p class="num">2.</p>
          <p>身份证复印件(签字）</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
        <div class="field">
          <p class="num">3.</p>
          <p>商标代理委托书(盖章)</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
        <div class="field">
          <p class="num">4.</p>
          <p>清晰的商标图样</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
        <div class="field">
          <p class="num">5.</p>
          <p>注册类别及小项</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
      </div>
    </div>
    <hr v-show="show" />
    <div class="materials">
      <div class="materialsBox">
        <p class="title">完成后客户获得的资料</p>
        <div class="field">
          <p class="num">1.</p>
          <p>{{Cl!==''? Cl[0]:'商标注册证（电子版）'}}</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
        <div class="field">
          <p class="num">2 .</p>
          <p>{{Cl!==''? Cl[1]:'纸质版商标证（彩打）'}}</p>
          <img src="../imger/AR/bei.png" alt="问标网" class="bei" />
        </div>
      </div>
    </div>
    <span v-show="show" id="beizhu">备注:平台可协助提供个体户营业执照办理业务</span>
  </div>
</template>

<script>
export default {
  props:["show",'Cl'],

};
</script>

<style scoped lang="scss">
hr {
  width: 90%;
  height: 0.0625rem /* 1px -> 0.0625rem */;
  border: none;
  border-top: 0.0625rem /* 1px -> 0.0625rem */ dashed #4D89F1;
  margin-top: 1.9375rem /* 31px -> 1.9375rem */;
}
.AR01 {
  width: 100%;
  margin-top: 2.6875rem /* 43px -> 2.6875rem */;
  border: 0.0625rem /* 1px -> 0.0625rem */ solid #eaeaea;
  padding-bottom: 2.75rem /* 44px -> 2.75rem */;
  margin-bottom: 2.75rem /* 44px -> 2.75rem */;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  #beizhu {
    font-size: 0.875rem /* 14px -> 0.875rem */;
    color: #999;
    position: relative;
    bottom: -1.25rem /* -20px -> -1.25rem */;
  }
  .header {
    width: 100%;
    height: 3.125rem /* 50px -> 3.125rem */;
    padding: 0.625rem /* 10px -> 0.625rem */;
    box-sizing: border-box;
    font-size: 1.25rem /* 20px -> 1.25rem */;
    color: #333333;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    .lan {
      width: 0.3125rem /* 5px -> 0.3125rem */;
      height: 1.6875rem /* 27px -> 1.6875rem */;
      margin-right: 0.875rem /* 14px -> 0.875rem */;
      background-color: #4d89f1;
    }
  }
  .box {
    width: 100%;
    margin-top: 3.75rem /* 60px -> 3.75rem */;
    .title {
      width: 100%;
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      .titletubiaoleft {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-right: 1.25rem /* 20px -> 1.25rem */;
      }
      .titletubiaoright {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-left: 1.25rem /* 20px -> 1.25rem */;
      }
    }
    .wear {
      padding-left: 0.8125rem /* 13px -> 0.8125rem */;
      padding-right: 0.8125rem /* 13px -> 0.8125rem */;
      margin-top: 2.125rem /* 34px -> 2.125rem */;
      font-size: 1.25rem /* 20px -> 1.25rem */;
      color: #333333;
      line-height: 2.25rem /* 36px -> 2.25rem */;
      text-indent: 2rem; //首行缩进
    }
    .advantage {
      width: 100%;
      height: 13.125rem /* 210px -> 13.125rem */;
      margin-top: 3.375rem /* 54px -> 3.375rem */;
      display: flex;
      justify-content: space-evenly;
      .advantageBox {
        width: 15.625rem /* 250px -> 15.625rem */;
        height: 13.125rem /* 210px -> 13.125rem */;
        padding-top: 8.125rem /* 130px -> 8.125rem */;
        font-size: 1.125rem /* 18px -> 1.125rem */;
        background-size: contain;
        background-repeat: no-repeat;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .benefi {
      width: 100%;
      height: 21.9375rem /* 351px -> 21.9375rem */;
      margin-top: 4.5rem /* 72px -> 4.5rem */;
      display: flex;
      .margin {
        padding-top: 5rem /* 80px -> 5rem */;
        box-sizing: border-box;
      }
      .benefibox {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        .left {
          width: 1.0625rem /* 17px -> 1.0625rem */;
          height: 100%;
          font-size: 3.75rem /* 60px -> 3.75rem */;
          color: #4d89f1;
          margin-right: 1.5rem /* 24px -> 1.5rem */;
        }

        .right {
          width: 13.125rem /* 210px -> 13.125rem */;
          height: 100%;
          margin-top: 0.625rem /* 10px -> 0.625rem */;
          font-size: 0.875rem /* 14px -> 0.875rem */;
          color: #666666;
          line-height: 2.1875rem /* 35px -> 2.1875rem */;
          .rightTitle {
            font-size: 1.25rem /* 20px -> 1.25rem */;
            color: #4d4d4d;
          }
          .benefiicon01 {
            height: 4rem /* 64px -> 4rem */;
            width: 5.1875rem /* 83px -> 5.1875rem */;
            margin-left: 5.4375rem /* 87px -> 5.4375rem */;
            margin-top: 1.25rem /* 20px -> 1.25rem */;
          }
        }
      }
    }
  }
  .banner {
    width: 70.3125rem /* 1125px -> 70.3125rem */;
    height: 10rem /* 160px -> 10rem */;
  }
  .step {
    width: 100%;
    height: 18.8125rem /* 301px -> 18.8125rem */;
    position: relative;
    #stepWord {
      font-size: 0.875rem /* 14px -> 0.875rem */;
      color: #ef783b;
      position: absolute;
      bottom: -1.25rem /* -20px -> -1.25rem */;
      left: 50%;
      transform: translateX(-50%);
    }
    .lan {
      position: absolute;
      top: 5.8125rem /* 93px -> 5.8125rem */;
      left: 50%;
      transform: translateX(-50%);
      width: 56rem /* 896px -> 56rem */;
      height: 0.125rem /* 2px -> 0.125rem */;
      background-color: #ff840c;
    }
    .number {
      position: absolute;
      top: 4.625rem /* 74px -> 4.625rem */;
      left: 50%;
      transform: translateX(-50%);
      width: 73.125rem /* 1170px -> 73.125rem */;
      height: 11.875rem /* 190px -> 11.875rem */;
      display: flex;
      justify-content: space-evenly;
      .numberbox {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .numberboxnum {
          width: 2.3125rem /* 37px -> 2.3125rem */;
          height: 2.3125rem /* 37px -> 2.3125rem */;
          background-color: #fff0e1;
          border: 0.125rem /* 2px -> 0.125rem */ solid #ff840c;
          border-radius: 50%;
          font-size: 1.375rem /* 22px -> 1.375rem */;
          color: #ff840c;
          text-align: center;
          line-height: 2.3125rem /* 37px -> 2.3125rem */;
        }
        .numberboxtitle {
          font-size: 1.125rem /* 18px -> 1.125rem */;
          color: #333333;
        }
        .numberboxwear {
          width: 190px;
          font-size: 1rem /* 16px -> 1rem */;
          color: #666666;
          line-height: 1.6rem /* 25.6px -> 1.6rem */;
        }
      }
    }
  }
  .materials {
    width: 100%;
    display: flex;
    margin-top: 2.5rem /* 40px -> 2.5rem */;
    .materialsBox {
      width: 50%;
      .title {
        font-size: 1.25rem /* 20px -> 1.25rem */;
        color: #555555;
        margin-left: 2.0625rem /* 33px -> 2.0625rem */;
      }
      .field {
        display: flex;
        font-size: 1.125rem /* 18px -> 1.125rem */;
        color: #666666;
        position: relative;
        line-height: 2.5rem /* 40px -> 2.5rem */;
        margin-left: 3.5rem /* 56px -> 3.5rem */;
        margin-top: 1.25rem /* 20px -> 1.25rem */;
        .num {
          line-height: 1.875rem /* 30px -> 1.875rem */;
          font-size: 2.25rem /* 36px -> 2.25rem */;
          color: #4d89f1;
        }
        .bei {
          position: absolute;
          bottom: 0.625rem /* 10px -> 0.625rem */;
          left: 0.5rem /* 8px -> 0.5rem */;
          width: 14.1875rem /* 227px -> 14.1875rem */;
          height: 0.5rem /* 8px -> 0.5rem */;
        }
      }
    }
  }
}
</style>