<template>
  <!-- 无执照注册页面 -->
  <div class="index">
    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch />

    <div class="build"></div>
    <ServerTop
      title="无执照注册商标"
      wera="办个体户执照+申请注册商标"
      jiage="1000"
    />

    <div class="importance">
      <div class="header">
        <div class="lan"></div>
        <p>服务详情</p>
      </div>
      <div class="title">
        <img src="../imger/AR/title.png" alt="无营业执照注册商标-问标网" class="titletubiaoleft" />
        <p>服务介绍</p>
        <img src="../imger/AR/title.png" alt="无营业执照注册商标-问标网" class="titletubiaoright" />
      </div>
      <div class="word">
        <p>
          所谓“创业未动·商标先行”主要指的是创业者在未办理营业执照之前已经想好了未来要用的品牌名字而要申请注册商标保护的，平台可协助办理临时个体户营业执照来注册商标到个人名下，全程贴心办理，助力创业者先人一步完成商标注册，防止商标被人抢注！ 
        </p>
      </div>
      <div class="bigbox">
        <div class="title">
          <img src="../imger/AR/title.png" alt="无营业执照注册商标-问标网" class="titletubiaoleft" />
          <p>服务优势</p>
          <img src="../imger/AR/title.png" alt="无营业执照注册商标-问标网" class="titletubiaoright" />
        </div>
        <div class="advantage">
          <div class="advantageBox one">
            <p class="advantageBoxword">
              <span class="advantageBoxTitle">正规专业</span>
              <span class="advantageBoxWera"
                >国家商标局备案机构，全程人工1对1服务</span
              >
            </p>
          </div>
          <div class="advantageBox tow">
            <p class="advantageBoxword">
              <span class="advantageBoxTitle">快速安全</span>
              <span class="advantageBoxWera">专业团队，无障碍快速对接服务</span>
            </p>
          </div>
          <div class="advantageBox three">
            <p class="advantageBoxword">
              <span class="advantageBoxTitle">服务保障</span>
              <span class="advantageBoxWera"
                >全国正规代理机构，保姆式贴心服务</span
              >
            </p>
          </div>
          <div class="advantageBox for">
            <p class="advantageBoxword">
              <span class="advantageBoxTitle">风险把控</span>
              <span class="advantageBoxWera"
                >大数据和人工双重检索，最大降低风险</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="box4">
        <div class="title">
          <img src="../imger/AR/title.png" alt="无营业执照注册商标-问标网" class="titletubiaoleft" />
          <p>商标价值</p>
          <img src="../imger/AR/title.png" alt="无营业执照注册商标-问标网" class="titletubiaoright" />
        </div>
        <img src="../imger/Insurance/jiazhi.png" id="jiazhi" alt="商标价值" />
        <div class="box4word01">
          <span>提升品牌价值</span>
          <span class="box4wordwera"
            >提升品牌识别度，赢得客户信任，打造宣传利器</span
          >
        </div>
        <div class="box4word02">
          <span>增加无形资产
</span>
          <span class="box4wordwera"
            >企业上市、高企认定、科技项目验收、投标重要指标</span
          >
        </div>
        <div class="box4word03">
          <span>提前抢占市场</span>
          <span class="box4wordwera"
            >抢先占领市场，避免恶性竞争，利于知产维权</span
          >
        </div>
      </div>
    </div>

    <div class="process">
      <ServerFoot :data="serverData" :show="true"/>
      <ServerCl :show="true" Cl=""/>
    </div>

    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";
import ServerTop from "../components/serverTop.vue";
import ServerFoot from "../components/serverFoot.vue";
import ServerCl from "../components/serverCl.vue";
import Title from "../components/trademarkRegistration/title.vue";

export default {
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    TradHeader,
    Title,
    ServerTop,
    ServerFoot,
    ServerCl
  },
  data() {
    return {
      serverData: [
        {
          title: "双重检索 ",
          content: "全国大数据智能检索和人工专业检索双重精准检索 ",
        },
        {
          title: "专属顾问服务",
          content: "专属资深顾问一对一服务，制定有效保护方案",
        },
        {
          title: "准备注册材料",
          content: "确认申请人资质，准备对应商标注册申请材料",
        },
        {
          title: "递交商标局",
          content: "资料审核通过，全程高效无缝对接提交商标局",
        },
        {
          title: "状态跟踪",
          content: "及时同步官方数据，注册进度在线随时可查",
        },
        {
          title: "获取证书",
          content: "商标注册通过后，统一派发商标注册证",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.one {
  background-image: url("../imger/Insurance/one.png");
  background-size: 100% 100%;
}
.tow {
  background-image: url("../imger/Insurance/2.png");
  background-size: 100% 100%;
}
.three {
  background-image: url("../imger/Insurance/3.png");
  background-size: 100% 100%;
}
.for {
  background-image: url("../imger/Insurance/4.png");
  background-size: 100% 100%;
}
.index {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .build {
    width: 100%;
    height: 28.75rem /* 460px -> 28.75rem */;
    background-image: url("../imger/License/banner.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .importance {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 6.25rem /* 100px -> 6.25rem */;
    .header {
      width: 75rem /* 1200px -> 75rem */;
      height: 3.125rem /* 50px -> 3.125rem */;
      padding: 0.625rem /* 10px -> 0.625rem */;
      box-sizing: border-box;
      font-size: 1.25rem /* 20px -> 1.25rem */;
      color: #333333;
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      .lan {
        width: 0.3125rem /* 5px -> 0.3125rem */;
        height: 1.6875rem /* 27px -> 1.6875rem */;
        margin-right: 0.875rem /* 14px -> 0.875rem */;
        background-color: #4d89f1;
      }
    }
    .title {
      width: 100%;
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3.125rem /* 50px -> 3.125rem */;
      .titletubiaoleft {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-right: 1.25rem /* 20px -> 1.25rem */;
      }
      .titletubiaoright {
        width: 1.625rem /* 26px -> 1.625rem */;
        height: 100%;
        margin-left: 1.25rem /* 20px -> 1.25rem */;
      }
    }
    .word {
      margin-top: 3.125rem /* 50px -> 3.125rem */;
      width: 75rem /* 1200px -> 75rem */;
      font-size: 1.25rem /* 20px -> 1.25rem */;
      color: #333;
      line-height: 2.6875rem /* 43px -> 2.6875rem */;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-indent: 2.5rem /* 40px -> 2.5rem */;
    }
    .bigbox {
      width: 74.8125rem /* 1197px -> 74.8125rem */;
      display: flex;
      flex-direction: column;
      .title {
        width: 100%;
        font-size: 1.5rem /* 24px -> 1.5rem */;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3.125rem /* 50px -> 3.125rem */;
        .titletubiaoleft {
          width: 1.625rem /* 26px -> 1.625rem */;
          height: 100%;
          margin-right: 1.25rem /* 20px -> 1.25rem */;
        }
        .titletubiaoright {
          width: 1.625rem /* 26px -> 1.625rem */;
          height: 100%;
          margin-left: 1.25rem /* 20px -> 1.25rem */;
        }
      }
      .bigboxBox {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 3.125rem /* 50px -> 3.125rem */;
        #phone {
          width: 32.75rem;
          height: 37rem;
          position: relative;
          left: 9.375rem /* 150px -> 9.375rem */;
        }
        .bigboxBoxleft {
          display: flex;
          flex-direction: column;
          // position: relative;
          // left: -6.25rem /* -100px -> -6.25rem */;
          .bigboxBoxtiao {
            display: flex;
            align-items: center;
            margin-bottom: 2.25rem /* 36px -> 2.25rem */;
            #gou {
              margin-right: 0.625rem /* 10px -> 0.625rem */;
              width: 1.5625rem /* 25px -> 1.5625rem */;
              height: 1.5rem /* 24px -> 1.5rem */;
            }
            .bigboxBoxword {
              display: flex;
              flex-direction: column;
              font-size: 1.25rem /* 20px -> 1.25rem */;
              color: #333;
              #tiao {
                position: relative;
                top: -0.3125rem /* -5px -> -0.3125rem */;
                width: 6.0625rem /* 97px -> 6.0625rem */;
                height: 0.5rem /* 8px -> 0.5rem */;
              }
            }
          }
        }
      }
      .advantage {
        width: 100%;
        height: 8.375rem /* 134px -> 8.375rem */;
        display: flex;
        justify-content: space-around;
        margin-top: 3.125rem /* 50px -> 3.125rem */;
        margin-bottom: 3.125rem /* 50px -> 3.125rem */;
        .advantageBox {
          width: 17.5625rem /* 281px -> 17.5625rem */;
          height: 100%;
          .advantageBoxword {
            position: relative;
            left: 6.875rem /* 110px -> 6.875rem */;
            width: 10rem /* 160px -> 10rem */;
            height: 100%;
            display: flex;
            flex-direction: column;
            .advantageBoxTitle {
              font-size: 1.25rem /* 20px -> 1.25rem */;
              color: #333;
              margin-top: 1.875rem /* 30px -> 1.875rem */;
            }
            .advantageBoxWera {
              font-size: 1rem /* 16px -> 1rem */;
              color: #666;
              margin-top: 0.625rem /* 10px -> 0.625rem */;
            }
          }
        }
      }
    }
    .box4 {
      width: 75rem /* 1200px -> 75rem */;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .title {
        width: 100%;
        font-size: 1.5rem /* 24px -> 1.5rem */;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3.125rem /* 50px -> 3.125rem */;
        .titletubiaoleft {
          width: 1.625rem /* 26px -> 1.625rem */;
          height: 100%;
          margin-right: 1.25rem /* 20px -> 1.25rem */;
        }
        .titletubiaoright {
          width: 1.625rem /* 26px -> 1.625rem */;
          height: 100%;
          margin-left: 1.25rem /* 20px -> 1.25rem */;
        }
      }
      #jiazhi {
        width: 25.625rem /* 410px -> 25.625rem */;
        height: 24rem /* 384px -> 24rem */;
        margin-top: 3.125rem /* 50px -> 3.125rem */;
        margin-bottom: 3.125rem /* 50px -> 3.125rem */;
      }

      .box4word01 {
        position: absolute;
        left: 0;
        top: 8.75rem /* 140px -> 8.75rem */;
        width: 23rem /* 368px -> 23rem */;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 1.25rem /* 20px -> 1.25rem */;
        font-weight: bold;
        color: #333333;
        .box4wordwera {
          font-size: 1rem /* 16px -> 1rem */;
          color: #7c8ba7;
          font-weight: normal;
          margin-top: 0.625rem /* 10px -> 0.625rem */;
        }
      }
      .box4word02 {
        position: absolute;
        left: 0;
        top: 23.75rem /* 380px -> 23.75rem */;
        width: 23rem /* 368px -> 23rem */;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 1.25rem /* 20px -> 1.25rem */;
        font-weight: bold;
        color: #333333;
        .box4wordwera {
          font-size: 1rem /* 16px -> 1rem */;
          color: #7c8ba7;
          font-weight: normal;
          margin-top: 0.625rem /* 10px -> 0.625rem */;
        }
      }
      .box4word03 {
        position: absolute;
        right: 0;
        top: 14.375rem /* 230px -> 14.375rem */;
        width: 23rem /* 368px -> 23rem */;
        display: flex;
        flex-direction: column;
        font-size: 1.25rem /* 20px -> 1.25rem */;
        font-weight: bold;
        color: #333333;
        .box4wordwera {
          font-size: 1rem /* 16px -> 1rem */;
          color: #7c8ba7;
          font-weight: normal;
          margin-top: 0.625rem /* 10px -> 0.625rem */;
        }
      }
    }
  }
  .process {
    width: 75rem /* 1200px -> 75rem */;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}
</style>