import { render, staticRenderFns } from "./serverCl.vue?vue&type=template&id=656d7bdc&scoped=true&"
import script from "./serverCl.vue?vue&type=script&lang=js&"
export * from "./serverCl.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./serverCl.vue?vue&type=style&index=0&id=656d7bdc&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "656d7bdc",
  "13abf8b6"
  
)

export default component.exports